body {
  margin: 0;
 
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0em;
  -webkit-font-smoothing: antialiased;
font-family: 'Poppins';
  font-style: normal;
  color: black;
}

